export const useCoreStore = defineStore("coreStore", () => {
  const vk = ref("https://vk.com/itsgamelight");
  const tg = ref("https://t.me/gamelight");
  const getContacts = async () => {
    return useApi().contacts.list();
  };
  const getArticles = async (slug: string) => {
    return useApi().articles.retrieve({ slug });
  };
  return {
    vk,
    tg,
    getContacts,
    getArticles,
  };
});
